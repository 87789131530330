import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Дослідіть наші послуги в SprintShine Studio
			</title>
			<meta name={"description"} content={"Індивідуальний догляд за вашим дорогоцінним автомобілем"} />
			<meta property={"og:title"} content={"Наші послуги | Дослідіть наші послуги в SprintShine Studio"} />
			<meta property={"og:description"} content={"Індивідуальний догляд за вашим дорогоцінним автомобілем"} />
			<meta property={"og:image"} content={"https://velvanobiz.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://velvanobiz.com/img/15_104863.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://velvanobiz.com/img/15_104863.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://velvanobiz.com/img/15_104863.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://velvanobiz.com/img/15_104863.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://velvanobiz.com/img/15_104863.png"} />
			<meta name={"msapplication-TileImage"} content={"https://velvanobiz.com/img/15_104863.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Найкращий догляд за автомобілем
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				У SprintShine Studio ми розуміємо, що ваш автомобіль - це більше, ніж просто транспортний засіб, це частина вашого стилю життя. Саме тому ми пропонуємо комплексний набір послуг, спрямованих на підтримку та покращення зовнішнього вигляду вашого автомобіля. Якщо вам потрібне ретельне прибирання після тривалої подорожі або детальний догляд, щоб ваш автомобіль сяяв для особливої події, ми знайдемо для вас ідеальне рішення.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Контакти
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://velvanobiz.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" quarkly-title="Statistics-7">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="40%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://velvanobiz.com/img/5.jpg"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					padding="40px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="--primary" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
					Наші послуги в деталях
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="normal 700 42px/1.2 --fontFamily-sans">
					Ось короткий перелік спеціалізованих послуг, які ми пропонуємо в SprintShine Studio:
					</Text>
					<Box
						min-width="100px"
						margin="30px 0px 30px 0px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						lg-margin="50px 0px 0px 0px"
						md-margin="40px 0px 0px 0px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Зовнішнє миття
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Виняткове очищення: Використовуючи струмені води під високим тиском і м'які ганчірки, ми гарантуємо бездоганну чистоту екстер'єру вашого автомобіля без жодного ризику подряпин.
   <br/><br/>
   Воскове покриття: Додає захисний шар для збереження фарби та надання автомобілю нового вигляду.
							<br/><br/>
   Очищення шин: Шини та диски ретельно очищаються, щоб видалити гальмівний пил і дорожній бруд.

							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Оздоблення салону
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Глибоке прибирання салону: Ми пилососимо та миємо шампунем килими та оббивку салону, щоб видалити плями та неприємні запахи.
							<br/><br/>
   Кондиціонер для шкіри: Захищає та зволожує шкіряний салон, запобігаючи появі тріщин та розривів.
							<br/><br/>
   Поліроль для приладової панелі: Забезпечує бездоганний вигляд панелі приладів і консолі та захист від ультрафіолетового випромінювання.

							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Захист лакофарбового покриття
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Передові рішення для покриттів: Наші керамічні та воскові покриття захищають фарбу вашого автомобіля від впливу факторів навколишнього середовища.
							<br/><br/>
   Видалення подряпин: Ми ретельно відшліфуємо всі подряпини, відновлюючи оригінальне лакофарбове покриття.
							<br/><br/>
   Нанесення герметика: Цей додатковий етап забезпечує захист від солі, бруду та плям.

							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 35px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Очищення моторного відсіку
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Деталізація двигуна: Чистий двигун працює краще. Ми знежирюємо та миємо ваш моторний відсік, збільшуючи термін служби його компонентів.
							<br/><br/>
   Захисне покриття: Запобігає появі іржі та корозії під капотом.

							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Реставрація фар
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Відновлення яскравості: Ми повертаємо фарам їх первісну яскравість, значно покращуючи видимість і безпеку вночі.
							<br/><br/>
   Захисний герметик: Захищає ваші фари від майбутнього окислення та пожовтіння.

							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 35px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Індивідуальні запити
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Послуги на замовлення: Індивідуальні варіанти деталізації для задоволення конкретних потреб або підготовки до особливих подій.
							<br/><br/>
   Інсталяція аксесуарів: Ми можемо встановити широкий спектр аксесуарів - від висококласного аудіообладнання до декоративних елементів.

							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px" quarkly-title="Advantages/Features-24">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					lg-width="100%"
				>
					Заплануйте своє обслуговування
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Щоб дізнатися більше про те, що ми можемо зробити для вашого автомобіля, або записатися на обслуговування, будь ласка, зв'яжіться з нами або відвідайте SprintShine Studio. Наша команда готова допомогти покращити експлуатаційні характеристики та естетику вашого автомобіля за допомогою наших професійних послуг.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Відчуйте різницю SprintShine - ваш автомобіль заслуговує на найкраще!
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://velvanobiz.com/img/6.jpg"
					max-width="400px"
					border-radius="8px"
					height="100%"
					object-fit="cover"
					lg-width="100%"
					lg-max-width="none"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});